export const ERROR_MESSAGES = {
    UNAUTHORIZED: "Unauthorized access",
    FORBIDDEN: "Forbidden access",
    INVALID_TOKEN: "Invalid or expired token",
    USER_NOT_FOUND: "User not found",
    INVALID_CREDENTIALS: "Invalid credentials",
    PAGE_NOT_FOUND: "Page Not Found",
    DUPLICATE_DOCUMENT: "Duplicate value error",
    VALIDATION_FAILED: "Validation failed",
    DOCUMENT_NOT_FOUND: "Document not found",
    INTERNAL_ERROR: "Internal server error",
    VALIDATION_ERROR:"Please fill this field",
    LESS_THAN_15_CHARACTER:"This field must contain 15 characters or less.",
    SPECIAL_CHARACTER:"Special characters are not allowed",
    CHARACTER_EXCEED:"API name cannot exceed 15 characters",
    STATUS: "error",
    CONNECT_API: "Please connect the API",
    CONNECT_OR_REMOVE : "Please connect or Remove the APIs which are not connected",
    SERVER_ERROR: "No response received from the server. Please check your connection.",
    ALPHANUMERIC_WITHOUT_SPACE: "API name must be alphanumeric without spaces.",
    INCOMPLETE_FIELDS: "All fields need to be filled out. Please complete or remove any empty fields."

  };
  
  export const ERROR_CODES = {
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
  };
  