export const extractLastPart = (path) => {
  const parts = path.split("/");
  return parts[parts.length - 1];
};

export const extractPath = (url, baseUrl = "http://localhost") => {
  
  url = url.replace(/\{\{.*?\}\}/g, baseUrl);

  try {
    const fullUrl = url.startsWith("http") ? url : baseUrl + url;
    const path = new URL(fullUrl).pathname;
    const maxLength = 18;
    const displayPath = path.length > maxLength ? path.substring(0, maxLength) + "..." : path;
    return displayPath;
  } catch (error) {
    console.error("Invalid URL:", url, error);
    return "Invalid path";
  }
};

export const splitOnUpperCase = (str) => {
  return str.replace(/([a-z])([A-Z])/g, "$1 $2");
};

export const getSpecificPath = (pathArray, defaultPath = "No valid path") => {
  if (!Array.isArray(pathArray) || pathArray.length === 0) {
    return defaultPath;
  }
  return pathArray.length === 1
    ? pathArray[0]
    : pathArray[pathArray.length - 1];
};

export const getTruncatedPath = (pathArray, maxLength = 15, defaultPath = "No valid path") => {
  const specificPath = getSpecificPath(pathArray, defaultPath);

  return specificPath.length > maxLength
    ? specificPath.substring(0, maxLength) + "..."
    : specificPath;
};

export const getActionFromUrl =(path)=> {
  let lastPart = path[path.length - 1];
  const isIDPattern = /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/.test(lastPart); 
  if (isIDPattern && path.length > 1) {
      lastPart = path[path.length - 2];
  }
  let resultItem = lastPart;
  for (let i = 0; i < path.length; i++) {
      if (path[i].includes(':') || path[i].includes('{') ) {
          resultItem = path[i - 1];
          return resultItem; 
      }
      if(path[i].includes('?')){
          resultItem = path[i];
          return resultItem; 
      }
  }
  return lastPart;  
}
