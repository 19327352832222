import { useState, useEffect } from "react";
import { fetchInitialNodes, searchByQueryAPI } from "../components/ApiHelper.js/GetApi.js";

export const useFetchInitialData = (searchApiQuery) => {
    const [apidata, setApidata] = useState([]);
    const [loading, setLoading] = useState(false); 
    const [error, setError] = useState(null); 
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); 
            setError(null);
            try {
                const data = searchApiQuery.length ? await searchByQueryAPI(searchApiQuery) : await fetchInitialNodes();
                setApidata(data);
                setError(error);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
            finally {
                setLoading(false); 
            }
        };
        fetchData();
    }, [searchApiQuery]);
    return { apidata, loading, error };
};
