export const danglingNodeChecker = (nodeList, edgeList) => {
  var isDangling = null;
  if (nodeList.length === 1) {
    isDangling = false;
    return isDangling;
  }
  const nodeKeys = flattenNodeListIntoObject(nodeList);
  const connectionResultNodeKeys = iterateEdgeListAndMarkAvailableNodes(
    nodeKeys,
    edgeList
  );
  isDangling = checkNodeConnectionStatus(connectionResultNodeKeys);
  return isDangling;
};

const flattenNodeListIntoObject = (nodeList) => {
  const obj = nodeList.reduce((acc, node) => {
    acc[node.id] = false;
    return acc;
  }, {});
  return obj;
};

const iterateEdgeListAndMarkAvailableNodes = (nodeKeys, edgeList) => {
  for (let edge of edgeList) {
    const { source: sourceId, target: targetId } = edge;
    if (nodeKeys.hasOwnProperty(sourceId)) nodeKeys[sourceId] = true;
    if (nodeKeys.hasOwnProperty(targetId)) nodeKeys[targetId] = true;
  }

  return nodeKeys;
};

const checkNodeConnectionStatus = (nodeKeys) => {
  let result = false;
  for (let key in nodeKeys) {
    if (nodeKeys[key] === false) {
      result = true;
      return result;
    }
  }
  return result;
};
