import { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL, ENV_ID } from "../components/ApiHelper.js/GetApi";

const useFetchSavedApis = () => {
  const [savedApis, setSavedApis] = useState([]);
  const [loading, setLoading] = useState(true);
  const [err, setErr] = useState(null);

  const fetchSavedApis = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/rapis/${ENV_ID}`);
      setSavedApis(response.data.savedApis);
    } catch (error) {
      console.error("Error fetching data:", error);
      setErr(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSavedApis();
  }, []);

  return { savedApis, loading, err, fetchSavedApis };
};

export default useFetchSavedApis;
