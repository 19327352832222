import { ERROR_MESSAGES } from './errors';

export const lengthValidator = (value) => {
    if (value.length >= 16) {
        return ERROR_MESSAGES.LESS_THAN_15_CHARACTER;
    }
    return "";
};

export const validateApiName = (name) => {
    const regex = /^[a-zA-Z0-9]*$/;
    return regex.test(name);
};

export const validateApiNameandLimit = (name) => {
    const regex = /^[a-zA-Z0-9]{1,15}$/;
    return regex.test(name);
  };

export const specialCharValidator = (value) => {
    if (!validateApiName(value)) {
        return ERROR_MESSAGES.SPECIAL_CHARACTER;
    }
    return "";
};

export const emptyCheckValidator = (value) => {
    if (value.trim() === "") {
      return ERROR_MESSAGES.VALIDATION_ERROR; 
    }
    return "";
  };
  

export const validate = (value, strategies) => {
    for (let strategy of strategies) {
        const error = strategy(value);
        if (error) {
            return error;
        }
    }
    return "";
};
