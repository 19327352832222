import React, { useEffect, useRef, useState } from "react";
import { Disclosure } from "@headlessui/react";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/20/solid";
import { SearchComponent } from "./components/SearchComponent.js";
import { extractPath } from "./components/Utils/CommonFunctions.js";
import { CheckIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { renameRelationalAPI } from "../src/components/ApiHelper.js/GetApi.js";

const ApiSection = ({
  searchApiQuery,
  setSearchApiQuery,
  filters,
  nodes,
  items,
  handleNodeSelectChange,
  onRefresh,
}) => {
  const [editingId, setEditingId] = useState(null);
  const [sectionId, setSectionId] = useState(null);
  const [editText, setEditText] = useState("");
  const inputRef = useRef(null);
  const containerRef = useRef(null);
  const handleEditClick = (sectionId, optionId, name, text) => {
    setSectionId(sectionId);
    setEditingId(optionId);
    setEditText(name);
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
    setOpenPopupId(null);
  };
  const handleCancelClick = () => {
    setEditingId(null);
    setEditText("");
  };

  const handleRename = async () => {
    if (!editText.trim()) {
      alert("Name cannot be empty.");
      return;
    }

    try {
      const response = await renameRelationalAPI(editingId, editText);
      setEditingId(null);
      onRefresh();
    } catch (error) {
      setEditingId(null);
    }
  };

  useEffect(() => {
    if (editingId !== null && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [editingId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setEditingId(null);
        setOpenPopupId(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [openPopupId, setOpenPopupId] = useState(null);
  const handlePopupToggle = (optionId) => {
    setOpenPopupId((prevId) => (prevId === optionId ? null : optionId));
  };

  return (
    <div className="col-span-1 mt-4 ms-6">
      <div className="flex items-baseline justify-between">
        <h1 className="text-xl font-bold tracking-tight text-primary">API</h1>
      </div>
      <SearchComponent
        searchApiQuery={searchApiQuery}
        setSearchApiQuery={setSearchApiQuery}
      />

      {filters.map((section) => (
        <div
          key={section.id}
          data-testid="main-data"
          className="border-t-2 border-gray-200 pt-4"
        >
          <Disclosure
            as="div"
            key={section.id}
            className="h-auto md:max-h-full lg:max-h-80 overflow-y-auto overflow-x-hidden pb-3"
            defaultOpen={true}
          >
            {({ open }) => (
              <>
                <h3 className="-mx-2 -my-3 flow-root">
                  <Disclosure.Button className="flex w-full items-center justify-between border-b border-gray-200 bg-white px-2 py-3 text-gray-400 hover:text-gray-500 transition ease-in-out duration-300">
                    <span className="font-semibold text-gray-700">
                      {section.name}
                    </span>
                    <span className="ml-6 flex items-center">
                      {open ? (
                        <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                      ) : (
                        <ChevronDownIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      )}
                    </span>
                  </Disclosure.Button>
                </h3>
                <Disclosure.Panel
                  className={`pt-5 transition-all ease-in-out duration-300 ${
                    open ? "max-h-[500px]" : "max-h-0"
                  }`}
                >
                  <div className="space-y-3">
                    {section.options.map((option, optionIdx) => {
                      const isSelected = nodes.some((node) => {
                        if (node.id === option.id) {
                          return true;
                        }
                        return false;
                      });

                      const node = items.find((node) => node._id === option.id);
                      const method =
                        node && node.request ? node.request.method : "";
                      const methodBadgeColors = {
                        POST: "bg-green-500",
                        PUT: "bg-orange-500",
                        DELETE: "bg-red-500",
                        GET: "bg-[#65B1FB]",
                      };
                      const badgeColor =
                        methodBadgeColors[method] || "bg-gray-500";
                      return (
                        <div
                          key={optionIdx}
                          ref={containerRef}
                          data-testid="url-data"
                          className="flex items-center justify-between relative"
                        >
                          <div
                            onClick={() => {
                              if (
                                editingId !== option.id &&
                                option.StatusCodeNumber !== 0
                              ) {
                                handleNodeSelectChange(
                                  section.id,
                                  option.id,
                                  option.label,
                                  option.nodeName,
                                  !isSelected
                                );
                              }
                            }}
                            className={`flex items-center w-full ${
                              option.StatusCodeNumber === 0
                                ? "pointer-events-none opacity-50"
                                : ""
                            }`}
                          >
                            <span
                              className={`inline-flex items-center justify-center py-0.5 w-12 text-[10px] rounded text-white ${badgeColor}`}
                              data-tip={method}
                              data-for="tooltip"
                            >
                              {method}
                            </span>
                            <div className="group cursor-pointer flex items-center w-full">
                              <span className="ml-2 cursor-pointer ">
                                {editingId === option.id ? (
                                  <input
                                    ref={inputRef}
                                    value={editText}
                                    onChange={(e) =>
                                      setEditText(e.target.value)
                                    }
                                    className="border border-gray-200 py-0.5 focus:outline-none"
                                  />
                                ) : // extractPath(option.url)
                                option.nodeName.length > 15 ? (
                                  option.nodeName.slice(0, 15) + "..."
                                ) : (
                                  option.nodeName
                                )}
                              </span>
                              {editingId === option.id && (
                                <div className="flex items-center space-x-2 ml-3">
                                  <button
                                    onClick={handleRename}
                                    className="flex items-center justify-center h-6 w-6 border border-gray-300 rounded bg-white text-gray-500 hover:bg-gray-100 hover:text-gray-700 transition"
                                  >
                                    <CheckIcon className="h-3 w-3" />
                                  </button>
                                  <button
                                    onClick={handleCancelClick}
                                    className="flex items-center justify-center h-6 w-6 border border-gray-300 rounded bg-white text-gray-500 hover:bg-gray-100 hover:text-gray-700 transition"
                                  >
                                    <XMarkIcon className="h-3 w-3" />
                                  </button>
                                </div>
                              )}

                              {editingId !== option.id && (
                                <div className="absolute -top-[36px] left-1/2 transform -translate-x-1/2 scale-0 origin-top bg-gray-800 py-1 px-2 text-xs text-white rounded shadow-md group-hover:scale-100 max-w-48 z-50 whitespace-wrap break-words">
                                  {option.url}
                                  <svg
                                    className="absolute text-gray-800 w-3 h-3 left-1/2 transform -translate-x-1/2 -bottom-3"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 255 255"
                                    xmlSpace="preserve"
                                  >
                                    <polygon
                                      className="fill-current"
                                      points="0,0 127.5,127.5 255,0"
                                    />
                                  </svg>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="flex space-x-2">
                            {!isSelected ? (
                              <button
                                onClick={() =>
                                  handleNodeSelectChange(
                                    section.id,
                                    option.id,
                                    option.label,
                                    option.nodeName,
                                    true
                                  )
                                }
                                className={`h-8 w-8 flex items-center justify-center ${
                                  option.StatusCodeNumber === 0 &&
                                  "cursor-not-allowed"
                                }`}
                                disabled={option.StatusCodeNumber === 0}
                                title={
                                  option.StatusCodeNumber === 0
                                    ? "Action is disabled"
                                    : "Add node"
                                }
                              >
                                <PlusCircleIcon
                                  className={`h-5 w-5 ${
                                    option.StatusCodeNumber === 0
                                      ? "text-green-300"
                                      : "text-green-400"
                                  }`}
                                  aria-hidden="true"
                                />
                              </button>
                            ) : (
                              <button
                                onClick={() =>
                                  handleNodeSelectChange(
                                    section.id,
                                    option.id,
                                    option.label,
                                    option.nodeName,
                                    false
                                  )
                                }
                                className={`h-8 w-8 flex items-center justify-center ${
                                  option.StatusCodeNumber === 0 &&
                                  "cursor-not-allowed"
                                }`}
                                disabled={option.StatusCodeNumber === 0}
                                title={
                                  option.StatusCodeNumber === 0
                                    ? "Action is disabled"
                                    : "Remove node"
                                }
                              >
                                <MinusCircleIcon
                                  className={`h-5 w-5 ${
                                    option.StatusCodeNumber === 0
                                      ? "text-red-300"
                                      : "text-red-500"
                                  }`}
                                  aria-hidden="true"
                                />
                              </button>
                            )}
                            <button
                              onClick={() => handlePopupToggle(option.id)}
                              className={`h-8 w-8 flex items-center justify-center ${
                                option.StatusCodeNumber === 0 &&
                                "cursor-not-allowed"
                              }`}
                              disabled={option.StatusCodeNumber === 0}
                            >
                              <EllipsisVerticalIcon
                                className={`h-5 w-5 ${
                                  option.StatusCodeNumber === 0
                                    ? "text-gray-300"
                                    : "text-gray-500"
                                }`}
                                aria-hidden="true"
                              />
                            </button>

                            {openPopupId === option.id && (
                              <div
                                className="absolute bg-white border border-gray-200 shadow-lg p-2 rounded-sm popup-container"
                                style={{ top: "100%", right: 5, zIndex: 10 }}
                                ref={containerRef}
                              >
                                <ul className="space-y-2 text-sm">
                                  <li
                                    onClick={() =>
                                      handleEditClick(
                                        section.id,
                                        option.id,
                                        option.nodeName,
                                        extractPath(option.url)
                                      )
                                    }
                                    className="cursor-pointer hover:bg-gray-100 px-2 py-1 rounded"
                                  >
                                    Rename
                                  </li>
                                </ul>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      ))}
    </div>
  );
};

export default ApiSection;
