import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { PencilIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { BASE_URL, ENV_ID } from "../ApiHelper.js/GetApi";
import ExistAPIModal from "./ExistAPIModal";
import { lengthValidator, specialCharValidator, validateApiName, emptyCheckValidator, validate } from "../../constants/validator"
import useFetchSavedApis from "../../customhooks/useFetchSavedApis";

const MasterRename = ({ isOpen, graphName, onCancel, onConfirm }) => {
  const { savedApis, fetchSavedApis } = useFetchSavedApis();
  const [newName, setNewName] = useState("");
  const [err, setErr] = useState("");
  const [apiExistModal, setApiExistModal] = useState(false)

  const handleInputChange = (event) => {
    const { value } = event.target;
    setNewName(value);
    setErr(!value ? "" : validate(value, [lengthValidator, specialCharValidator]));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleConfirmRename();
    }
  };

  const handleConfirmRename = async () => {
    const strategies = [emptyCheckValidator, lengthValidator, specialCharValidator];
    const errorMessage = validate(newName, strategies);
    if (errorMessage) {
      setErr(errorMessage);
      return;
    }
    if (apiExistModal === false) {
      const nameExist = savedApis.includes(newName);
      if (nameExist) {
        setApiExistModal(true);
        return;
      }
    }
    try {
      await axios.put(`${BASE_URL}/rapis/rename/${ENV_ID}`, {
        oldname: graphName,
        newname: newName,
      });
      onConfirm();
      fetchSavedApis();
    } catch (error) {
      console.error("Error renaming the item:", error);
    }
  };

  const handleReplace = (e) => {
    handleConfirmRename()
    setApiExistModal(false);
  };

  const handleCancel = () => {
    setApiExistModal(false);
  };

  return (
    <>
      <Dialog open={isOpen} onClose={onCancel} className="relative z-10">
        <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <PencilIcon
                    aria-hidden="true"
                    className="h-6 w-6 text-red-600"
                  />
                </div>
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <DialogTitle as="h3" className="text-base  leading-6 text-gray-900">Are you sure you want to Rename&nbsp;
                    <span className="font-semibold underline">{graphName}</span>
                    &nbsp; graph? Please enter a new name below and proceed.
                  </DialogTitle>

                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      <input
                        type="text"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:border-complementary focus:bg-white"
                        placeholder="new name"
                        onChange={handleInputChange}
                        maxLength={16}
                        onKeyDown={handleKeyDown}
                      />
                    </p>
                  </div>
                  {err && <p className="text-sm text-red-600 mt-2">{err}</p>}
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={handleConfirmRename}
                  className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 lg:w-20"
                >
                  Submit
                </button>
                <button
                  type="button"
                  onClick={onCancel}
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  Cancel
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
      <ExistAPIModal
        isOpen={apiExistModal}
        onClose={() => setApiExistModal(false)}
        onReplace={handleReplace}
        onCancel={handleCancel}
      />
    </>
  );
};

export default MasterRename;
