
import dagre from "dagre";

export const getLayoutedElements = (nodes, edges, direction = "TB") => {
  const isHorizontal = direction === "LR";

  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setGraph({
    rankdir: isHorizontal ? "LR" : "TB",
    marginx: 20,
    marginy: 20,
  });
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const nodeWidth = 150;
  const nodeHeight = 50;

  nodes.forEach((node) => {
    const width = node.width || nodeWidth;
    const height = node.height || nodeHeight;
    dagreGraph.setNode(node.id, { width, height });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  const layoutedNodes = nodes.map((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);

    if (nodeWithPosition) {
      const { x, y } = nodeWithPosition;
      node.position = {
        x: x - (node.width || nodeWidth) / 2,
        y: y - (node.height || nodeHeight) / 2,
      };
    } else {
      console.error(`Node ${node.id} not found in layout graph.`);
    }

    return node;
  });

  const layoutedEdges = edges.map((edge) => {
    const sourceNode = dagreGraph.node(edge.source);
    const targetNode = dagreGraph.node(edge.target);

    if (sourceNode && targetNode) {
      return {
        ...edge,
        sourceX: sourceNode.x,
        sourceY: sourceNode.y,
        targetX: targetNode.x,
        targetY: targetNode.y,
      };
    } else {
      console.error(
        `Edge from ${edge.source} to ${edge.target} cannot be laid out because one of the nodes is missing.`
      );
      return edge; 
    }
  });

  return { nodes: layoutedNodes, edges: layoutedEdges };
};

