import { useState, useEffect, useCallback } from "react";
import {
    fetchInitialNodes,
    searchByQueryAPI,
} from "../components/ApiHelper.js/GetApi.js";


export const useFetchInitialData = (searchApiQuery) => {
    const [apidata, setApidata] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = searchApiQuery.length
                    ? await searchByQueryAPI(searchApiQuery)
                    : await fetchInitialNodes();
                setApidata(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [searchApiQuery]);
    return apidata;
};
