import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import ReactFlow, { ConnectionLineType, Panel, useNodesState, useEdgesState, Background } from "reactflow";
import "./App.css";
import SidebarLayout from "./components/SidebarLayout.js";
import CustomEdge from "./components/CustomEdge.jsx";
import ApiSection from "./ApiSection.js";
import AllDataSave from "./components/CustomSaveModal.js";
import { Menu, MenuButton } from "@headlessui/react";
import { BookmarkIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from "@heroicons/react/24/outline";
import { TrashIcon } from "@heroicons/react/20/solid";
import { getTruncatedPath } from "./components/Utils/CommonFunctions.js";
import { getLayoutedElements } from "./components/Utils/LayoutUtils";
import ConfirmDialog from "./components/Utils/ConfirmDialog";
import { useFetchInitialData } from "./customhooks/useApiDataFetch.js";
import { useNodeManagement } from "./customhooks/useNodeManagement.js";
import CustomDialog from "./components/Utils/ModalDialog.js";

const initialState = {
  relationVal: [],
  selectedNodes: [],
  searchApiQuery: [],
  open: false,
  modalOpen: false,
  showConfirmDialog: false,
  sidebardata: "",
  deletedNodeId: null,
  graphName: "",
  status: "add",
  isDialogOpen: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_RELATION_VALUE":
      return { ...state, relationVal: action.payload };
    case "SET_SELECTED_NODES":
      return { ...state, selectedNodes: action.payload };
    case "SET_SEARCH_API_QUERY":
      return { ...state, searchApiQuery: action.payload };
    case "TOGGLE_OPEN":
      return { ...state, open: !state.open };
    case "SET_MODAL_OPEN":
      return { ...state, modalOpen: action.payload };
    case "TOGGLE_CONFIRM_DIALOG":
      return { ...state, showConfirmDialog: action.payload };
    case "SET_SIDEBAR_DATA":
      return { ...state, sidebardata: action.payload };
    case "SET_DELETED_NODE_ID":
      return { ...state, deletedNodeId: action.payload };
    case "SET_GRAPH_NAME":
      return { ...state, graphName: action.payload };
    case "SET_STATUS":
      return { ...state, status: action.payload };
    case "TOGGLE_DIALOG":
      return { ...state, isDialogOpen: action.payload };
    case "DELETE_NODE_RELATION":
      const updatedRelationVal = state.relationVal.reduce((acc, item) => {
        if (!item[action.payload]) {
          acc.push(item);
        }
        return acc;
      }, []);
      if (updatedRelationVal.length !== state.relationVal.length) {
        return {
          ...state,
          relationVal: updatedRelationVal,
        };
      }
      return state;
    default:
      return state;
  }
};
const App = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { apidata, loading, error } = useFetchInitialData(state.searchApiQuery);

  const edgeTypes = useMemo(
    () => ({
      custom: (props) => (
        <CustomEdge
          {...props}
          relationVal={state.relationVal}
          setRelationValue={(newValue) =>
            dispatch({
              type: "SET_RELATION_VALUE",
              payload: newValue,
            })
          }
          setIsDialogOpen={(isOpen) =>
            dispatch({
              type: "TOGGLE_DIALOG",
              payload: isOpen,
            })
          }
        />
      ),
    }),
    [state.relationVal, dispatch]
  );
  const arrayKey = Object.keys(apidata).find((key) =>
    Array.isArray(apidata[key])
  );

  const items = apidata[arrayKey];

  const filters = items
    ? [
        {
          id: "baseUrl",
          name: "Base URL",
          options: items.map((node) => {
            const pathArray = node.request?.url?.path || [];
            const truncatedPath = getTruncatedPath(pathArray);
            // const nodeName = getActionFromUrl(pathArray);
            const nodeName = node.name;
            return {
              id: node._id,
              label: truncatedPath,
              url: node.request.url.raw,
              nodeName: nodeName,
              StatusCodeNumber: node.StatusCodeNumber,
            };
          }),
        },
      ]
    : [];

  const { handleNodeSelectChange, onConnect, updateNodeRelations } =
    useNodeManagement(
      items,
      edges,
      setEdges,
      setNodes,
      (selectedNodes) =>
        dispatch({ type: "SET_SELECTED_NODES", payload: selectedNodes }),
      (deletedNodeId) =>
        dispatch({ type: "DELETE_NODE_RELATION", payload: deletedNodeId })
    );

  const onLayout = useCallback(
    (direction) => {
      const { nodes: layoutedNodes, edges: layoutedEdges } =
        getLayoutedElements(nodes, edges, direction);
      setNodes([...layoutedNodes]);
      setEdges([...layoutedEdges]);
    },
    [nodes, edges, setEdges, setNodes]
  );

  const handleRunClick = () =>
    dispatch({ type: "SET_MODAL_OPEN", payload: true });
  useEffect(() => {
    edges.forEach((edge) => {
      const { source, target } = edge;
      updateNodeRelations(source, target._id);
    });
  }, [edges, updateNodeRelations]);

  const handleNodeDelete = useCallback(
    (nodeId) => {
      const newNodes = nodes.filter((node) => node.id !== nodeId);
      const newEdges = edges.filter(
        (edge) => edge.source !== nodeId && edge.target !== nodeId
      );
      setNodes(newNodes);
      setEdges(newEdges);
      dispatch({
        type: "SET_SELECTED_NODES",
        payload: state.selectedNodes.filter(
          (option) => option.value !== nodeId
        ),
      });
    },
    [nodes, edges, state.selectedNodes, setNodes, setEdges]
  );

  const handleDeleteAll = () =>
    dispatch({ type: "TOGGLE_CONFIRM_DIALOG", payload: true });
  const handleConfirmDelete = () => {
    setNodes([]);
    setEdges([]);
    dispatch({ type: "TOGGLE_CONFIRM_DIALOG", payload: false });
  };

  const handleCancelDelete = () =>
    dispatch({ type: "TOGGLE_CONFIRM_DIALOG", payload: false });
  const handelSidebarData = (sidebarData) =>
    dispatch({ type: "SET_SIDEBAR_DATA", payload: sidebarData });
  const nameOfGraph = (nameOfGraph) =>
    dispatch({ type: "SET_GRAPH_NAME", payload: nameOfGraph });

  const onNodesDelete = (deletedNodes) => {
    if (deletedNodes.length > 0) {
      const deletedNodeId = deletedNodes[0].id;
      dispatch({ type: "DELETE_NODE_RELATION", payload: deletedNodeId });
    }
  };

  const handleRefresh = () => {
    dispatch({ type: "SET_SEARCH_API_QUERY", payload: [] });
  };

  return (
    <>
      <div className="bg-white h-full text-sm text-gray-700 lg:overflow-hidden ">
        <div className="h-full">
          <main className="mx-auto max-w-full h-full flex">
            <section aria-labelledby="products-heading" className="w-full">
              <div className="grid grid-cols-1 gap-x-6 gap-y-10 md:grid-cols-3 h-full">
                <ApiSection
                  searchApiQuery={state.searchApiQuery}
                  setSearchApiQuery={(query) =>
                    dispatch({ type: "SET_SEARCH_API_QUERY", payload: query })
                  }
                  filters={filters}
                  nodes={nodes}
                  items={items}
                  handleNodeSelectChange={handleNodeSelectChange}
                  onRefresh={handleRefresh}
                />
                <div className="col-span-2 bg-gray-100">
                  <div className="relative h-[90vh]">
                    <ReactFlow
                      nodes={nodes}
                      edges={edges}
                      onNodesChange={onNodesChange}
                      onEdgesChange={onEdgesChange}
                      onConnect={onConnect}
                      onNodesDelete={onNodesDelete}
                      connectionLineType={ConnectionLineType.SmoothStep}
                      edgeTypes={edgeTypes}
                      fitView
                      proOptions={{ hideAttribution: true }}
                    >
                      <Panel position="top-right">
                        <div className="flex items-center">
                          <Menu
                            as="div"
                            className="relative inline-block text-left"
                          >
                            <div>
                              <MenuButton className="group inline-flex mt-3 justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                                <button
                                  className="rounded-md  px-3.5 py-1.5 text-sm mr-2 text-primary shadow-sm ring-1 ring-inset ring-primary hover:bg-gray-50"
                                  onClick={() => onLayout("TB")}
                                >
                                  Vertical layout
                                </button>

                                <button
                                  className="rounded-md   px-3.5 py-1.5 text-sm  text-primary shadow-sm ring-1 ring-inset ring-primary hover:bg-gray-50"
                                  onClick={() => onLayout("LR")}
                                >
                                  Horizontal layout
                                </button>

                                <span
                                  className="inline-flex ml-3 items-center rounded-md px-1.5 py-1 text-xs font-medium text-blue-700 border border-red-500"
                                  title="Clear"
                                  onClick={handleDeleteAll}
                                >
                                  <TrashIcon className="h-5 w-4 text-red-500" />
                                </span>
                                <span
                                  className="inline-flex ml-3 items-center rounded-md bg-blue-500 px-1.5 py-1 text-xs font-medium ring-1 ring-inset ring-blue-700/10"
                                  onClick={handleRunClick}
                                  title="Save"
                                >
                                  {state.modalOpen && (
                                    <AllDataSave
                                      nodeList={nodes}
                                      edgeList={edges}
                                      onClose={() =>
                                        dispatch({
                                          type: "SET_MODAL_OPEN",
                                          payload: false,
                                        })
                                      }
                                      onSaveSuccess={handelSidebarData}
                                      graphName={state.graphName}
                                      methodStatus={state.status}
                                      relationVal={state.relationVal}
                                    />
                                  )}
                                  <BookmarkIcon className="h-5 w-4 text-white" />
                                  <h1>{state.modalOpen}</h1>
                                </span>
                                <span
                                  className="inline-flex ml-3 items-center rounded-s-md bg-primary ms-4 px-1.5 py-1 text-xs font-medium ring-1 ring-inset ring-blue-700/10"
                                  onClick={() =>
                                    dispatch({ type: "TOGGLE_OPEN" })
                                  }
                                >
                                  {state.open ? (
                                    <>
                                      <ChevronDoubleRightIcon className="h-5 w-5 text-white" />
                                    </>
                                  ) : (
                                    <>
                                      <ChevronDoubleLeftIcon className="h-5 w-4 text-white" />
                                    </>
                                  )}
                                </span>
                                <SidebarLayout
                                  setNodeList={setNodes}
                                  setEdgeList={setEdges}
                                  deletedNode={state.deletedNodeId}
                                  open={state.open}
                                  setOpen={() =>
                                    dispatch({ type: "TOGGLE_OPEN" })
                                  }
                                  sidebarData={state.sidebardata}
                                  nameOfGraph={nameOfGraph}
                                  setStatus={(status) =>
                                    dispatch({
                                      type: "SET_STATUS",
                                      payload: status,
                                    })
                                  }
                                  relationVal={state.relationVal}
                                  setRelationValue={(value) =>
                                    dispatch({
                                      type: "SET_RELATION_VALUE",
                                      payload: value,
                                    })
                                  }
                                />
                              </MenuButton>
                            </div>
                          </Menu>
                        </div>
                        <button
                          onClick={() => {
                            if (state.selectedNodes.length > 0) {
                              handleNodeDelete(state.selectedNodes[0].value);
                            }
                          }}
                        ></button>
                      </Panel>
                      <Background />
                    </ReactFlow>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
      </div>
      {state.showConfirmDialog && (
        <ConfirmDialog
          isOpen={state.showConfirmDialog}
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
        />
      )}
      <CustomDialog
        open={state.isDialogOpen}
        onClose={() => dispatch({ type: "TOGGLE_DIALOG", payload: false })}
        title="Success"
        message="Data saved successfully!"
        buttonText="Close"
        onButtonClick={() =>
          dispatch({ type: "TOGGLE_DIALOG", payload: false })
        }
      />
    </>
  );
};

export default App;
