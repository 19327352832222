// nodeUtils.js
export const getNodeStyle = (method) => {
  switch (method) {
    case "POST":
      return { backgroundColor: "#22c55e", color: "white" };
    case "PUT":
      return { backgroundColor: "#f97316", color: "white" };
    case "DELETE":
      return { backgroundColor: "#ef4444", color: "white" };
    case "GET":
      return { backgroundColor: "#65B1FB", color: "white" };
    default:
      return { backgroundColor: "gray", color: "white" };
  }
};
