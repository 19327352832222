import React from 'react';
import { Disclosure } from '@headlessui/react';
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { SearchComponent } from "./components/SearchComponent.js";
import { extractPath } from './components/Utils/CommonFunctions.js';

const ApiSection = ({ searchApiQuery, setSearchApiQuery, filters, nodes, items, handleNodeSelectChange }) => {

  return (
    <div className="md:col-span-3 lg:col-span-2 mt-4 ms-6">
      <div className="flex items-baseline justify-between">
        <h1 className="text-xl font-bold tracking-tight text-primary">API</h1>
      </div>
      <SearchComponent
        searchApiQuery={searchApiQuery}
        setSearchApiQuery={setSearchApiQuery}
      />

      {filters.map((section) => (
        <div
          key={section.id}
          data-testid= "main-data"
          className="border-t-2 border-gray-200 pt-4"
        >
          <Disclosure
            as="div"
            key={section.id}
            className="h-auto md:max-h-full lg:max-h-80 overflow-y-auto overflow-x-hidden pb-3"
          >
            {({ open }) => (
              <>
                <h3 className="-mx-2 -my-3 flow-root">
                  <Disclosure.Button className="flex w-full items-center justify-between border-b border-gray-200 bg-white px-2 py-3 text-gray-400 hover:text-gray-500 transition ease-in-out duration-300">
                    <span className="font-semibold text-gray-700">
                      {section.name}
                    </span>
                    <span className="ml-6 flex items-center">
                      {open ? (
                        <ChevronUpIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      ) : (
                        <ChevronDownIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      )}
                    </span>
                  </Disclosure.Button>
                </h3>
                <Disclosure.Panel
                  className={`pt-5 transition-all ease-in-out duration-300 ${open ? "max-h-[500px]" : "max-h-0"
                    }`}
                >
                  <div className="space-y-3">
                    {section.options.map((option, optionIdx) => {
                      const isSelected = nodes.some((node) => {
                        if (node.id === option.id) {
                          return true;
                        }
                        return false;
                      });

                      const node = items.find(
                        (node) => node._id === option.id
                      );

                      const method = node && node.request ? node.request.method : "";
                      const methodBadgeColors = {
                        POST: "bg-green-500",
                        PUT: "bg-orange-500",
                        DELETE: "bg-red-500",
                        GET: "bg-[#65B1FB]",
                      };

                      const badgeColor = methodBadgeColors[method] || "bg-gray-500";
                    
                      return (
                        <div key={optionIdx} data-testid= "url-data" className="flex items-center justify-between relative">
                          <div onClick={() =>
                                  handleNodeSelectChange(
                                    section.id,
                                    option.id,
                                    option.label,
                                    option.nodeName,
                                    true
                                  )
                                } className="flex items-center w-full">
                            <span
                              className={`inline-flex items-center justify-center py-0.5 w-12 text-[10px] rounded text-white ${badgeColor}`}
                              data-tip={method}
                              data-for="tooltip"
                            >
                              {method}
                            </span>

                            <div className="group w-full">
                              <span className="ml-2">
                                {
                                  extractPath(option.url)
                                }
                              </span>
                              <div className="absolute -top-[36px] left-1/2 transform -translate-x-1/2 scale-0 origin-top bg-gray-800 py-1 px-2 text-xs text-white rounded shadow-md group-hover:scale-100 max-w-48 z-50 whitespace-wrap break-words">
                                {option.url}
                                <svg
                                  className="absolute text-gray-800 w-3 h-3 left-1/2 transform -translate-x-1/2 -bottom-3"
                                  x="0px"
                                  y="0px"
                                  viewBox="0 0 255 255"
                                  xmlSpace="preserve"
                                >
                                  <polygon
                                    className="fill-current"
                                    points="0,0 127.5,127.5 255,0"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                          <div className="flex space-x-2">
                            {!isSelected ? (
                              <button
                                onClick={() =>
                                  handleNodeSelectChange(
                                    section.id,
                                    option.id,
                                    option.label,
                                    option.nodeName,
                                    true
                                  )
                                }
                                className="h-8 w-8 flex items-center justify-center"
                              >
                                <PlusCircleIcon
                                  className="h-5 w-5 text-green-400"
                                  aria-hidden="true"
                                />
                              </button>
                            ) : (
                              <button
                                onClick={() =>
                                  handleNodeSelectChange(
                                    section.id,
                                    option.id,
                                    option.label,
                                    option.nodeName,
                                    false
                                  )
                                }
                                className="h-8 w-8 flex items-center justify-center"
                              >
                                <MinusCircleIcon
                                  className="h-5 w-5 text-red-500"
                                  aria-hidden="true"
                                />
                              </button>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      ))}
    </div>
  );
};

export default ApiSection;
