import React, { createContext, useState } from "react";

const SaveDataContext = createContext();

const SaveDataProvider = ({ children }) => {
  const [savedData, setData] = useState([]);

  const saveData = (datavalue) => {
    setData(datavalue);
  };

  const clearSavedData = () => {
    setData([]);
  };

  return (
    <SaveDataContext.Provider
      value={{ savedData, saveData, clearSavedData }}
    >
      {children}
    </SaveDataContext.Provider>
  );
};

export { SaveDataProvider, SaveDataContext };
