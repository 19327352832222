const getPositions = (adjacencyList) => {
  const nodePositions = {};
  const xSpacing = 100;
  const ySpacing = 100;

  function assignPositions(node, x, y) {
    if (nodePositions[node]) return;
    nodePositions[node] = { x: x, y: y };
    const children = adjacencyList[node];
    if (children && children.length > 0) {
      let startX = x - ((children.length - 1) * xSpacing) / 2;
      children.forEach((child, index) => {
        assignPositions(child, startX + index * xSpacing, y + ySpacing);
      });
    }
  }

  const allNodes = Object.keys(adjacencyList);
  const childNodes = new Set(allNodes.flatMap((node) => adjacencyList[node]));
  const rootNode = allNodes.find((node) => !childNodes.has(node));
  assignPositions(rootNode, 0, 0);

  return nodePositions;
};

export default getPositions;
