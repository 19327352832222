import React, { useState, useEffect, useContext } from "react";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import {
  ChevronDoubleRightIcon,
  EllipsisVerticalIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import axios from "axios";
import { BASE_URL, ENV_ID } from "./ApiHelper.js/GetApi";
import CustomDialog from "./Utils/ModalDialog";
import { getRelationalAPIByGraphNameAndEnvId } from "../components/ApiHelper.js/GetApi";
import useFetchSavedApis from "../customhooks/useFetchSavedApis";
import MasterDelete from "./Utils/MasterDelete";
import MasterRename from "./Utils/MasterRename";

const SidebarLayout = ({
  open,
  setOpen,
  setNodeList,
  setEdgeList,
  sidebarData,
  deletedNode,
  nameOfGraph,
  setStatus,
  relationVal,
  setRelationValue,
}) => {
  const { savedApis, loading, fetchSavedApis } = useFetchSavedApis();
  const [openOptionsIndex, setOpenOptionsIndex] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [graphName, setGraphName] = useState("");
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showRenameDialog, setShowRenameDialog] = useState(false);

  useEffect(() => {
    if (open === false) {
      setOpenOptionsIndex(null);
    }
  }, [open]);

  const handleToggleOptions = async (index, item) => {
    const graphName = item;
    nameOfGraph(graphName);
    setGraphName();
    const relationalAPIObject = await getRelationalAPIByGraphNameAndEnvId(
      graphName,
      ENV_ID
    );
    setStatus("update");
    setNodeList(relationalAPIObject?.graph?.nodeList || []);
    setEdgeList(relationalAPIObject?.graph?.edgeList || []);
    setRelationValue(relationalAPIObject?.graph?.contextData || []);
  };

  const handleDelete = (item) => {
    setGraphName(item);
    setShowConfirmDialog(true);
  };
  const handleRename = (item) => {
    setGraphName(item);
    setShowRenameDialog(true);
  };

  useEffect(() => {
    if (deletedNode != null && deletedNode !== "") {
      function deleteDependency(idToDelete) {
        const updatedData = [...relationVal];
        let dataChanged = false;

        for (let i = 0; i < updatedData.length; i++) {
          const key = Object.keys(updatedData[i])[0];

          if (key === idToDelete) {
            updatedData.splice(i, 1);
            dataChanged = true;
            i--;
          } else {
            const item = updatedData[i][key];
            if (item.dependencies) {
              const newDependencies = item.dependencies.filter(
                (dep) => dep._id !== idToDelete
              );
              if (newDependencies.length !== item.dependencies.length) {
                item.dependencies = newDependencies;
                dataChanged = true;
              }
            }
          }
        }
        if (dataChanged) {
          setRelationValue(updatedData);
        }
      }
      deleteDependency(deletedNode);
    }
  }, [deletedNode, setRelationValue, relationVal]);

  const onConfirm = () => {
    fetchSavedApis();
    setShowRenameDialog(false);
  };

  return (
    <>
      <Dialog className="relative z-10" open={open} onClose={setOpen}>
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <DialogPanel
                transition
                className="pointer-events-auto w-64 max-w-lg transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
              >
                <div className="flex h-full flex-col overflow-y-scroll bg-white py-3 shadow-xl">
                  <div className="px-2">
                    <div className="flex items-start justify-between">
                      <DialogTitle className="text-base font-semibold leading-6 text-gray-900">
                        <button
                          type="button"
                          className="absolute -left-11 z-50 rounded-md focus:outline-none"
                          onClick={() => setOpen(false)}
                        >
                          <div className="inline-flex ml-3 items-center rounded-s-md bg-primary ms-4 px-1.5 py-1 text-xs font-medium outline-none">
                            <ChevronDoubleRightIcon
                              className="h-5 w-4 text-white"
                              aria-hidden="true"
                            />
                          </div>
                        </button>
                      </DialogTitle>
                    </div>
                  </div>
                  <div className="relative flex-1 px-4">
                    <>
                      <div>
                        <h3 className="text-base leading-7 text-primary">
                          Saved Relation API
                        </h3>
                        <div className="border-b-2 border-gray-200 my-3"></div>
                        <div className="pt-2">
                          {loading ? (
                            <p>Loading...</p>
                          ) : (
                            savedApis.map((item, index) => (
                              <div key={index} className="mb-2 relative">
                                <button
                                  type="button"
                                  className="flex items-center justify-between rounded-md bg-primary px-2 py-1.5 text-sm text-white shadow-md w-full cursor-default"
                                >
                                  <span className="text-left max-w-36 truncate">
                                    {item}
                                  </span>

                                  <span
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setOpenOptionsIndex(
                                        openOptionsIndex === index
                                          ? null
                                          : index
                                      );
                                    }}
                                    className="flex items-center "
                                  >
                                    <EllipsisVerticalIcon
                                      aria-hidden="true"
                                      className="h-5 w-5 cursor-pointer"
                                    />
                                  </span>
                                </button>
                                {openOptionsIndex === index && (
                                  <div className="absolute right-0 mt-2 w-48 bg-white shadow-lg rounded-md overflow-hidden z-50">
                                    <div className="py-1">
                                      <button
                                        onClick={() => {
                                          handleRename(item);
                                          setOpenOptionsIndex(null);
                                        }}
                                        className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center"
                                      >
                                        <PencilIcon className="w-4 h-4 mr-2 inline-block" />
                                        Rename
                                      </button>
                                      <button
                                        onClick={() => {
                                          handleToggleOptions(index, item);
                                          setOpenOptionsIndex(null);
                                        }}
                                        className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center"
                                      >
                                        <PencilIcon className="w-4 h-4 mr-2 inline-block" />
                                        Edit Graph
                                      </button>
                                      <button
                                        onClick={() => {
                                          handleDelete(item);
                                          setOpenOptionsIndex(null);
                                        }}
                                        className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center"
                                      >
                                        <TrashIcon className="w-4 h-4 mr-2 inline-block" />
                                        Delete
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ))
                          )}
                        </div>
                      </div>
                    </>
                  </div>
                </div>
              </DialogPanel>
            </div>
          </div>
        </div>
      </Dialog>
      <CustomDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        title="Success"
        message="Data deleted successfully!"
        buttonText="Close"
        onButtonClick={() => setIsDialogOpen(false)}
      />

      {graphName && (
        <MasterDelete
          isOpen={showConfirmDialog}
          graphName={graphName}
          onCancel={() => setShowConfirmDialog(false)}
          onConfirm={onConfirm}
        />
      )}
      {graphName && showRenameDialog && (
        <MasterRename
          isOpen={showRenameDialog}
          graphName={graphName}
          onCancel={() => setShowRenameDialog(false)}
          onConfirm={onConfirm}
        />
      )}
    </>
  );
};

export default SidebarLayout;
