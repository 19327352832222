import React from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";


export function SearchComponent({ searchApiQuery, setSearchApiQuery }) {
  const handleSearchApi = (e) => {
    const query = e.target.value;
    setSearchApiQuery(query);
  };

  return (
    <div className="relative flex flex-1">
      <label htmlFor="search-field" className="sr-only">
        Search
      </label>
      <MagnifyingGlassIcon
        data-testid="search-icon"
        className="pointer-events-none absolute inset-y-0 left-0 h-full w-4 text-gray-400"
        aria-hidden="true"
      />
      <input
        onChange={handleSearchApi}
        value={searchApiQuery}
        id="search-field"
        className="block h-full w-full py-5 pl-6 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 outline-none"
        placeholder="Search..."
        type="search"
        name="search"
      />
    </div>
  );
}
