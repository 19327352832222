import React, { useState, useEffect, useContext, useReducer } from "react";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { PlusCircleIcon, BookmarkIcon } from "@heroicons/react/24/outline";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { ArrowLongRightIcon } from "@heroicons/react/20/solid";
import { fetchIndividualData } from "./ApiHelper.js/GetApi";
import { ERROR_MESSAGES } from '../constants/errors';
const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

const initialState = {
  sourceData: null,
  targetData: null,
  requests: [],
  isOpen: true,
  initialDataLoaded: false,
  editMode: false,
  editIndex: null,
  errors: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_SOURCE_DATA':
      return { ...state, sourceData: action.payload };
    case 'SET_TARGET_DATA':
      return { ...state, targetData: action.payload };
    case 'SET_REQUESTS':
      return { ...state, requests: action.payload };
    case 'SET_IS_OPEN':
      return { ...state, isOpen: action.payload };
    case 'SET_INITIAL_DATA_LOADED':
      return { ...state, initialDataLoaded: action.payload };
    case 'SET_EDIT_MODE':
      return { ...state, editMode: action.payload };
    case 'SET_EDIT_INDEX':
      return { ...state, editIndex: action.payload };
    case 'SET_ERRORS':
      return { ...state, errors: action.payload };
    default:
      return state;
  }
};

const CustomModal = ({ sourceId, targetId, relationVal, setRelationValue, setShowModal, setIsDialogOpen }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const fetchDataAsync = async () => {
      if (sourceId) {
        const newData = await fetchIndividualData(sourceId);
        dispatch({ type: 'SET_SOURCE_DATA', payload: newData.keys });
      }
      if (targetId) {
        const newData = await fetchIndividualData(targetId);
        dispatch({ type: 'SET_TARGET_DATA', payload: newData.keys });
      }
    };
    fetchDataAsync();
  }, [sourceId, targetId]);

  const findDependencyIndex = (relationVal, targetId, sourceId) => {
    return relationVal.findIndex((data) =>
      data[targetId]?.dependencies.some((dep) => dep._id === sourceId)
    );
  };

  const generateEditRequests = (dependencies) => {
    const editRequests = [];
    if (dependencies.request_fields) {
      editRequests.push(
        ...Object.entries(dependencies.request_fields).map(([request, response]) => ({
          type: "request",
          request,
          response,
        }))
      );
    }
    if (dependencies.response_fields) {
      editRequests.push(
        ...Object.entries(dependencies.response_fields).map(([response, request]) => ({
          type: "response",
          request,
          response,
        }))
      );
    }
    return editRequests;
  };

  useEffect(() => {
    if (relationVal.length === 0 || state.initialDataLoaded) return;
    const existingDataIndex = findDependencyIndex(relationVal, targetId, sourceId);
    if (existingDataIndex === -1) return;
    const dependencies = relationVal[existingDataIndex][targetId].dependencies.find((dep) => dep._id === sourceId);
    if (dependencies) {
      const editRequests = generateEditRequests(dependencies);
      dispatch({ type: 'SET_REQUESTS', payload: editRequests });
      dispatch({ type: 'SET_EDIT_MODE', payload: true });
      dispatch({ type: 'SET_EDIT_INDEX', payload: existingDataIndex });
    }
    dispatch({ type: 'SET_INITIAL_DATA_LOADED', payload: true });
  }, [relationVal, targetId, sourceId, state.initialDataLoaded]);

  const handleRequestSelect = (item, index) => {
    const newRequests = [...state.requests];
    newRequests[index].request = item;
    dispatch({ type: 'SET_REQUESTS', payload: newRequests });


  };
  useEffect(() => {
    const hasEmptyFields = state.requests.some(({ request, response }) => !request || !response);
    if (!hasEmptyFields) {
      dispatch({ type: 'SET_ERRORS', payload: [] });
    }
  }, [state.requests])

  const handleResponseChange = (value, index) => {
    const newRequests = [...state.requests];
    newRequests[index].response = value;
    dispatch({ type: 'SET_REQUESTS', payload: newRequests });
  };

  const validateRequests = () => {
    const hasEmptyFields = state.requests.some(({ request, response }) => !request || !response);

    if (hasEmptyFields) {
      dispatch({ type: 'SET_ERRORS', payload: [ERROR_MESSAGES.INCOMPLETE_FIELDS] });
      return false;
    }
    dispatch({ type: 'SET_ERRORS', payload: [] });
    return true;
  };

  const isEmptyObject = (obj) => Object.keys(obj).length === 0 && obj.constructor === Object;

  const findOrCreateDependency = (dependencies, sourceId) => {
    let dependency = dependencies.find((dep) => dep._id === sourceId);
    if (!dependency) {
      dependency = { _id: sourceId, request_fields: {}, response_fields: {} };
      dependencies.push(dependency);
    }
    return dependency;
  };

  const formatData = (targetId, requests) => {
    const formattedData = {
      [targetId]: { dependencies: [] },
    };
    requests.forEach((req) => {
      const { type, request, response } = req;
      let dependencyObj = findOrCreateDependency(formattedData[targetId].dependencies, sourceId);
      if (type === "request") {
        dependencyObj.request_fields[request] = isEmptyObject(response) ? null : response;
      } else if (type === "response") {
        dependencyObj.response_fields[response] = isEmptyObject(request) ? null : request;
      }
    });
    return formattedData;
  };

  const updateRelationValue = (currentVal, editMode, editIndex, newData) => {
    const updatedData = [...currentVal];
    if (editMode && editIndex !== null && editIndex >= 0 && editIndex < updatedData.length) {
      updatedData[editIndex] = newData;
    } else {
      updatedData.push(newData);
    }
    return updatedData;
  };

  const toggleDialog = (isOpen) => dispatch({ type: 'SET_IS_OPEN', payload: isOpen });
  const openDialog = () => setIsDialogOpen(true);
  const handleLogValues = () => {
    if (!validateRequests()) return;
    const formattedData = formatData(targetId, state.requests);
    const updatedData = updateRelationValue(relationVal, state.editMode, state.editIndex, formattedData);
    setRelationValue(updatedData);
    toggleDialog(false);
    openDialog();
  };

  const handleClose = () => {
    dispatch({ type: 'SET_IS_OPEN', payload: false });
    setShowModal(false);
  };

  const handleAddRequest = () => {
    dispatch({
      type: 'SET_REQUESTS',
      payload: [
        ...state.requests,
        { request: "", response: "", type: "request" }
      ],
    });
  };

  const handleAddResponse = () => {
    dispatch({
      type: 'SET_REQUESTS',
      payload: [
        ...state.requests,
        { request: "", response: "", type: "response" }
      ],
    });
  };

  const handleRemoveRequest = (index) => {
    dispatch({
      type: 'SET_REQUESTS',
      payload: state.requests.filter((_, idx) => idx !== index),
    });
  };

  const handleResponseSelect = (item, index) => {
    const newRequests = [...state.requests];
    newRequests[index].response = item;
    dispatch({ type: 'SET_REQUESTS', payload: newRequests });
  };

  const selectedSources = new Set(state.requests.map((req) => req.request));
  const selectedTargets = new Set(state.requests.map((req) => req.response));
  const filteredSourceData = state.sourceData ? state.sourceData.filter((item) => !selectedSources.has(item)) : [];
  const filteredTargetData = state.targetData ? state.targetData.filter((item) => !selectedTargets.has(item)) : [];

  const getFilteredTargetData = (currentIndex) => {
    if (!state.targetData) return [];
    const selectedValues = state.requests
      .filter((req, idx) => idx !== currentIndex && req.type === "response")
      .map((req) => req.request);
    return state.targetData.filter((item) => !selectedValues.includes(item));
  };

  return (
    <>
      <Dialog onClose={handleClose} open={state.isOpen}>
        <DialogBackdrop className="fixed inset-0 bg-opacity-75 transition-opacity" />
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel className="modal-popup relative transform rounded-xl bg-primary px-4 pb-4 pt-5 text-left shadow-xl transition-all">
              <button
                onClick={handleClose}
                className="close-button"
                title="close"
              >
                <XMarkIcon className="h-5 w-5 text-white" />
              </button>
              {state.requests.length > 0 && (
                <div className="grid grid-cols-2 mb-2.5">
                  <div className="col-span-1">
                    <span className="text-base text-white">Source API</span>
                  </div>
                  <div className="col-span-1">
                    <span className="text-base text-white">Target API</span>
                  </div>
                </div>
              )}
              <div className="max-h-64 overflow-y-auto overflow-x-hidden mb-2">
                {state.requests.map((request, index) => (
                  <div key={index} className="relative flex items-start mb-2">
                    {request.type === "request" ? (
                      <>
                        <div className="flex w-full">
                          <div className="flex flex-col gap-2 w-full">
                            <label className="text-white w-full text-sm">
                              Request
                            </label>

                            <Menu
                              as="div"
                              className="relative inline-block text-left"
                            >
                              <MenuButton className="inline-flex w-56 justify-between gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-50">
                                {request.request ||
                                  (state.sourceData
                                    ? state.sourceData.title
                                    : "Loading...")}
                                <ChevronDownIcon
                                  className="-mr-1 h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </MenuButton>
                              <MenuItems className="relative right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg focus:outline-none">
                                {filteredSourceData ? (
                                  <div className="py-1 max-h-20 overflow-y-auto">
                                    {filteredSourceData.map(
                                      (item, itemIndex) => (
                                        <MenuItem
                                          key={itemIndex}
                                          onClick={() =>
                                            handleRequestSelect(item, index)
                                          }
                                        >
                                          {({ active }) => (
                                            <a
                                              className={classNames(
                                                active
                                                  ? "bg-gray-100 text-gray-900"
                                                  : "text-gray-700",
                                                "block px-4 py-2 text-sm"
                                              )}
                                            >
                                              {item}
                                            </a>
                                          )}
                                        </MenuItem>
                                      )
                                    )}
                                  </div>
                                ) : (
                                  <div className="py-1 max-h-20 overflow-y-auto">
                                    <MenuItem disabled>
                                      <span className="block px-4 py-2 text-sm text-gray-400">
                                        Loading...
                                      </span>
                                    </MenuItem>
                                  </div>
                                )}
                              </MenuItems>
                            </Menu>
                          </div>
                          <ArrowLongRightIcon className="h-5 w-5 text-white mt-9 mx-1" />
                        </div>
                        <div className="flex flex-col gap-2 w-full">
                          <label className="text-white w-full text-sm">
                            Request
                          </label>
                          {request.type === "response" ? (
                            <input
                              type="text"
                              value={request.response}
                              onChange={(e) =>
                                handleResponseChange(e.target.value, index)
                              }
                              className="w-full px-3 py-2 text-sm text-gray-700 rounded-md bg-white shadow-sm"
                            />
                          ) : (
                            <Menu
                              as="div"
                              className="relative inline-block text-left"
                            >
                              <MenuButton className="inline-flex w-56 justify-between gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-700 shadow-sm hover:bg-gray-50">
                                {request.response || "Select Response"}
                                <ChevronDownIcon
                                  className="-mr-1 h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </MenuButton>
                              <MenuItems className="relative right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg focus:outline-none">
                                {filteredTargetData ? (
                                  <div className="py-1 max-h-20 overflow-y-auto">
                                    {filteredTargetData.map(
                                      (item, itemIndex) => (
                                        <MenuItem
                                          key={itemIndex}
                                          onClick={() =>
                                            handleResponseSelect(item, index)
                                          }>
                                          {({ active }) => (
                                            <a
                                              className={classNames(
                                                active
                                                  ? "bg-gray-100 text-gray-900"
                                                  : "text-gray-700",
                                                "block px-4 py-2 text-sm"
                                              )}
                                            >
                                              {item}
                                            </a>
                                          )}
                                        </MenuItem>
                                      )
                                    )}
                                  </div>
                                ) : (
                                  <div className="py-1">
                                    <MenuItem disabled>
                                      <span className="block px-4 py-2 text-sm text-gray-400">
                                        Loading...
                                      </span>
                                    </MenuItem>
                                  </div>
                                )}
                              </MenuItems>
                            </Menu>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flex w-full">
                          <div className="flex flex-col gap-2 w-full">
                            <label className="text-white w-full text-sm">
                              Response
                            </label>
                            <div className="w-56">
                              <input
                                type="text"
                                placeholder="Enter Response"
                                value={request.response}
                                onChange={(e) =>
                                  handleResponseChange(e.target.value, index)
                                }
                                className="w-full px-3 py-2 text-sm text-gray-700 rounded-md bg-white shadow-sm outline-none"
                              />
                            </div>
                          </div>
                          <ArrowLongRightIcon className="h-5 w-5 text-white mt-9 mx-1" />
                        </div>

                        <div className="flex flex-col gap-2 w-full">
                          <label className="text-white w-full text-sm">
                            Request
                          </label>
                          <Menu
                            as="div"
                            className="relative inline-block text-left"
                          >
                            <MenuButton className="inline-flex w-56 justify-between gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-700 shadow-sm hover:bg-gray-50">
                              {request.request ||
                                "Select Request" ||
                                request.response}
                              <ChevronDownIcon
                                className="-mr-1 h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </MenuButton>
                            <MenuItems className="relative right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg focus:outline-none">
                              {filteredTargetData ? (
                                <div className="py-1">
                                  {getFilteredTargetData(index).map(
                                    (item, itemIndex) => (
                                      <MenuItem
                                        key={itemIndex}
                                        onClick={() =>
                                          handleRequestSelect(item, index)
                                        }
                                      >
                                        {({ active }) => (
                                          <a
                                            className={classNames(
                                              active
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-700",
                                              "block px-4 py-2 text-sm"
                                            )}
                                          >
                                            {item}
                                          </a>
                                        )}
                                      </MenuItem>
                                    )
                                  )}
                                </div>
                              ) : (
                                <div className="py-1">
                                  <MenuItem disabled>
                                    <span className="block px-4 py-2 text-sm text-gray-400">
                                      Loading...
                                    </span>
                                  </MenuItem>
                                </div>
                              )}
                            </MenuItems>
                          </Menu>
                        </div>
                      </>
                    )}
                    {index >= 0 && (
                      <button
                        onClick={() => handleRemoveRequest(index)}
                        className="text-white"
                        title="Remove Dropdown"
                      >
                        <XMarkIcon className="h-4 w-4 mt-9 ms-2" />
                      </button>
                    )}
                  </div>
                ))}
              </div>
              {state.errors.length > 0 && (
                <div className="text-red-500 text-sm mb-2">
                  {state.errors.map((error, index) => (
                    <div key={index}>{error}</div>
                  ))}
                </div>
              )}
              <div className="flex justify-between items-end mt-6">

                <div className="flex gap-3">

                  <button
                    type="button"
                    onClick={handleAddRequest}
                    className={`flex gap-2 justify-center rounded-md px-3 py-2 text-sm text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:col-start-2
                        ${filteredTargetData.length === 0 || filteredSourceData.length === 0
                        ? 'bg-gray-300 text-gray-500 cursor-not-allowed opacity-50'
                        : 'bg-complementary_light hover:bg-complementary' 
                      }`}
                    disabled={filteredTargetData.length === 0 || filteredSourceData.length === 0}
                  >
                    Request
                    <PlusCircleIcon className="h-5 w-5" />
                  </button>

                  <button
                    type="button"
                    onClick={handleAddResponse}
                    className={`flex gap-2 justify-center rounded-md px-3 py-2 text-sm shadow-sm sm:col-start-1 sm:mt-0 focus:outline-none border text-white
                        ${filteredTargetData.length === 0 || filteredSourceData.length === 0
                        ? 'bg-gray-300 text-gray-500 border-gray-300 cursor-not-allowed opacity-50'
                        : 'bg-transparent border-white hover:bg-white hover:text-complementary'
                      }`}
                    disabled={filteredTargetData.length === 0 || filteredSourceData.length === 0}
                    data-autofocus
                  >
                    Response
                    <PlusCircleIcon className="h-5 w-5" />
                  </button>


                </div>
                {state.requests.length > 0 && (
                  <span title="save">
                    <BookmarkIcon
                      className="h-5 w-5 text-white cursor-pointer ms-2"
                      onClick={handleLogValues}
                    />
                  </span>
                )}
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default CustomModal;
