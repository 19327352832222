// utils/urlUtils.js
import { useParams } from "react-router-dom";

export const useQueryParam = (paramName) => {
  const { [paramName]: paramValue } = useParams();
  return paramValue;
};

// urlHelper.js
export function getIdFromUrl() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get("id");
}

// urlHelper.js
export function getIdFromPathname() {
  const pathname = window.location.pathname;
  const pathParts = pathname.split("/");
  const id = pathParts[pathParts.length - 1];
  return id;
}
